import { Box, Skeleton } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import FilteredGames from "../Components/Games/FilteredGames";
import { useGames } from "../Hooks/useGetGames";
import Carousel from "../Components/Carousel/Carousel";
import GameBannerAd from "../Components/Advertisement/GameBannerAd";
import ComicPanel from "../Components/Comics/ComicPanel";
import taktLogo from "../assets/LBPortal_Graphic_AdBG.webp";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

function Homepage() {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });
  const { data, isLoading } = useGames();

  const [skeleton, setSkeleton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(true);
    }, 1000);
  }, []);

  if (isLoading) return <div />;

  const carouselGames = data.games.filter(
    (game) => game.is_display_in_slideshow === true
  );

  const singlePlayer = data.games.filter(
    (item) => !item.description.includes("Multiplayer")
  );

  const multiPlayer = data.games.filter((item) =>
    item.description.includes("Multiplayer")
  );

  return (
    <>
      <Helmet>
        <title>Leaderbored</title>
        <link rel='canonical' href='https://www.leaderbored.gg' />
        <meta
          name='description'
          content='Page consisting of webcomics and free HTML5 games to play.'
        />
      </Helmet>

      <Box color='white'>
        <Skeleton isLoaded={skeleton}>
          <Carousel games={carouselGames} />
        </Skeleton>
        <Skeleton isLoaded={skeleton}>
          <GameBannerAd />
        </Skeleton>

        <Box id='comics'>
          <Skeleton isLoaded={skeleton}>
            <ComicPanel />
          </Skeleton>
        </Box>

        <Skeleton isLoaded={skeleton}>
          <Box
            bgImage={taktLogo}
            bgPos='center'
            bgSize='cover'
            borderRadius='20px'
            mb='40px'
            bgRepeat='no-repeat'
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <FilteredGames
              heading={"GAME OF THE WEEK"}
              games={[data.featuredGame]}
            />
          </Box>
        </Skeleton>
        <Skeleton isLoaded={skeleton}>
          <FilteredGames heading={"Single player"} games={singlePlayer} />
        </Skeleton>
        <Skeleton isLoaded={skeleton}>
          <GameBannerAd />
        </Skeleton>
        <Skeleton isLoaded={skeleton}>
          <FilteredGames heading={"Multiplayer"} games={multiPlayer} />
        </Skeleton>
      </Box>
    </>
  );
}

export default Homepage;
