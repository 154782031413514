import { Box, Button, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import adBg from "../../assets/GamesBanner_Graphic_BG.webp";
import mobileAdBg from "../../assets/GamesBanner_Graphic_MobileBG.png";
import Btn from "../UI/Btn";
import { motion } from "framer-motion";

function GameBannerAd() {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

  return (
    <>
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&family=Dosis:wght@800&display=swap');
        </style>
      </Helmet>
      <Box
        my='24px'
        maxW='960px'
        mx='auto'
        bgImage={{ base: mobileAdBg, sm: adBg }}
        bgSize='cover'
        bgPos='center'
        pos='relative'
        boxShadow='0 4px 8px #4A5568'
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box fontWeight='bold' pb={{ base: "16px" }} textAlign='center'>
          <Heading
            color='#0b2051'
            fontFamily={`'Dosis', sans-serif`}
            mb={{ md: "6px" }}
            // style={{
            //   // WebkitTextStroke: "1.5px #014C7A",
            //   WebkitTextStroke: "1.5px #014C7A",
            // }}
            fontSize='28px'
          >
            Do you want games on your site?
          </Heading>
          <Text
            color='black'
            textShadow='0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white'
            // fontSize='16px'
            fontFamily={`'Barlow', sans-serif`}
            fontWeight='bold'
          >
            Our games work on desktops and mobile devices.
            <br />
            {isLargerThan480 && <span>Contact us for more information!</span>}
          </Text>
          {!isLargerThan768 && (
            <Button
              boxShadow='0 3px #BC6022'
              mt={{ base: "8px" }}
              bgColor='secondary'
              px='16px'
              py='4px'
              variant='link'
              as={Link}
              to='/contact'
              borderRadius='20px'
              fontWeight='bold'
              color='primary'
              textShadow='.5px .5px white'
            >
              Contact us!
            </Button>
          )}
        </Box>
        {isLargerThan768 && (
          <Box
            pos='absolute'
            right='16px'
            top='50%'
            transform='translateY(-50%)'
          >
            <Btn
              as={Link}
              to='/contact'
              bgColor='secondary'
              shadowcolor='#BC6022'
              w={{ sm: "fit-content", md: "fit-content" }}
              ml='auto'
              color='#267AC2'
              textShadow='.5px .5px white'
            >
              Contact us!
            </Btn>
          </Box>
        )}
      </Box>
    </>
  );
}

export default GameBannerAd;
