import { Box, Image, Skeleton, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Rating from "../UI/Rating";
import { useEffect, useState } from "react";

function GameItem({ title, thumbnail, id, rating }) {
  const [skeleton, setSkeleton] = useState(false);

  const maxCharacters = 18;
  const trimmedTitle =
    title.length > maxCharacters
      ? `${title.slice(0, maxCharacters)}...`
      : title;

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(true);
    }, 500);
  }, []);

  return (
    <Box
      as={Link}
      to={`/games/${id}`}
      color='white'
      borderRadius='8px'
      boxShadow='0 4px 8px lightgray'
      padding='4px'
      pb='12px'
      bgColor='primary'
      _hover={{
        cursor: "pointer",
        textDecoration: "underline",
      }}
    >
      <Skeleton isLoaded={skeleton}>
        <Image
          marginBottom='16px'
          borderRadius='8px'
          alt={title}
          src={thumbnail}
          loading='lazy'
        />
      </Skeleton>
      <Rating justifyContent='center' rating={rating} />
      <Text textAlign='center' fontSize='xl'>
        {trimmedTitle}
      </Text>
    </Box>
  );
}

export default GameItem;
