import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { useEffect } from "react";

import Navbar from "./Navbar";
import background from "../assets/LeaderBoredPortal_Background.webp";
import Footer from "./Footer";

// import TSModal from "../Components/Advertisement/TSModal";
// import FloatingButton from "./Popover/FloatingButton";

function Layout() {
  const location = useLocation();
  const path = location.pathname;

  // const params = useParams();

  // THIS TRIGGERS THE FLOATING BUTTON'S SPEECH BUBBLE TO APPEAR WHEN THE MODAL CLOSES
  // const [showBubble, setShowBubble] = useState(false);

  // const [isMobile] = useMediaQuery("(max-width: 800px)");
  // const isPlayingMobile = isMobile && params.gameId;

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  // const modalCloseHandler = () => {
  //   // SHOW FLOATING BUTTON SPEECH BUBBLE WHEN MODAL CLOSES
  //   setShowBubble(true);
  // };

  return (
    <>
      {/* <TSModal onModalClose={modalCloseHandler} /> */}
      <Navbar />
      <Box
        pt='54px'
        pb='64px'
        minHeight='100vh'
        bg={`url(${background})`}
        bgAttachment='fixed'
        bgPos='top center'
      >
        <Box
          mt='56px'
          as='main'
          px={{ base: "4px", sm: "8px" }}
          mx='auto'
          minH='900px'
          maxW='1200px'
        >
          <Outlet />
          {/* <FloatingButton modalIsClose={showBubble} /> */}
        </Box>

        {/* {!isPlayingMobile && <FloatingButton modalIsClose={true} />} */}
      </Box>
      <Footer />
    </>
  );
}

export default Layout;
