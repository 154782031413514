import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image } from "@chakra-ui/react";

import CarouselItem from "./CarouselItem";
import "../../styles/Carousel.css";
import nextBtn from "../../assets/nextArrow.png";
import prevBtn from "../../assets/prevArrow.png";
import { motion } from "framer-motion";

const NextArrow = ({ onClick }) => {
  return (
    <Image
      height='20%'
      display='block'
      onClick={onClick}
      src={nextBtn}
      aria-roledescription='Next button'
      alt='Next button'
      pos='absolute'
      top='40%'
      right='16px'
      _hover={{ cursor: "pointer" }}
      _active={{ top: "42%" }}
    />
  );
};
const PrevArrow = ({ onClick }) => {
  return (
    <Image
      aria-roledescription='Previous button'
      alt='Previous button'
      height='20%'
      display='block'
      onClick={onClick}
      src={prevBtn}
      pos='absolute'
      top='40%'
      left='16px'
      _hover={{ cursor: "pointer" }}
      _active={{ top: "42%" }}
      zIndex='98'
    />
  );
};

const settings = {
  initialSlide: 1,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  appendDots: (dots) => (
    <div
      style={{
        backgroundColor: "#fff",
      }}
    >
      <ul style={{ marginBottom: "48px" }}> {dots} </ul>
    </div>
  ),
};

const Carousel = ({ games }) => {
  return (
    <Box
      overflow='hidden'
      padding={{ base: "0 0 12px", md: "12px" }}
      borderRadius='10px'
      bgColor='white'
      pos='relative'
      marginBottom='40px'
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box maxH='520px' as={Slider} {...settings}>
        {games.map((item) => {
          const { id, title_icon: title, banner } = item;
          return (
            <CarouselItem
              key={id}
              gameId={id}
              title={`https://api.leaderbored.gg${title.url}`}
              banner={`https://api.leaderbored.gg${banner.url}`}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Carousel;
