import {
  Box,
  Grid,
  Text,
  HStack,
  Button,
  VStack,
  Center,
  Image,
} from "@chakra-ui/react";

import lbLogo from "../assets/Leaderbored_logo_HD.webp";
import tsLogo from "../assets/taktyl_logo_1497x400px_black.webp";

function Footer() {
  return (
    <Box w='100%' bgColor='primary'>
      <Box color='white' mx='auto' maxW='1280px' py='24px' px='16px'>
        <Grid gridTemplateColumns={{ md: "7fr 3fr" }}>
          <VStack alignItems='start' gap='32px'>
            <Text>
              Leader, Bored is your go-to place for fun games. Play games online
              for free on desktop and mobile devices.
            </Text>
            <HStack color='white' textDecor='underline' gap='24px'>
              <Button color='white' variant='link'>
                Privacy Policy
              </Button>
              <Button color='white' variant='link'>
                Terms of Use
              </Button>
            </HStack>
          </VStack>

          <Box>
            <HStack gap='24px' align='center' justify='end'>
              <Box
                as='a'
                href='https://taktylstudios.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Image
                  cursor='pointer'
                  loading='lazy'
                  alt='Taktyl logo'
                  w='140px'
                  src={tsLogo}
                />
              </Box>
              <Box as='a' href='/'>
                <Image
                  loading='lazy'
                  alt='Leaderbored logo'
                  w='72px'
                  src={lbLogo}
                />
              </Box>
            </HStack>
            <Text mt='16px' textAlign='right' fontSize='12px'>
              Use of this website signifies that you agree with the Terms of
              Use. <br />
              Leader, Bored. All Rights Reserved.
            </Text>
          </Box>
        </Grid>

        <Center mt='40px' opacity='.7'>
          <Text textAlign='center'>Taktyl Studios, All Rights Reserved</Text>
        </Center>
      </Box>
    </Box>
  );
}

export default Footer;
